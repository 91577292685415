#root {
    height: 100vh;
  }

  .background-img{

    height: 100vh;
    width: 100%;
    background-image: url('./WhatsAppImage.jpg');
    background-attachment: fixed;
    background-size: cover;
    opacity: 0.4;;
  }